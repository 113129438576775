import PagesIndex from "../container/PagesIndex";

// ------Client live-------
// export const API_ENDPOINT = "https://api-sahay.patanmandal.org/api/";
// export const imagePath = "https://api-sahay.patanmandal.org/uploads/";
// // ------Client live-------

// ------Demo Client live-------
// export const API_ENDPOINT = "https://api-sahay.appworkdemo.com/api/";
// export const imagePath = "https://api-sahay.appworkdemo.com/uploads/";
// export const API_ENDPOINT = "https://api-sahay.appworkdemo.com/api/";
// export const imagePath = "https://api-sahay.appworkdemo.com/uploads/";
// ------Client live-------

// ------Appworkdemo live-------
export const API_ENDPOINT = "https://api-sahay.appworkdemo.com/api";
export const imagePath = "https://api-sahay.appworkdemo.com/uploads/";

///////////////////////////////////////////////////////////////////////
// export const API_ENDPOINT = "http://35.177.56.74:3030/api/";
// export const imagePath = "http://35.177.56.74:3030/uploads/";
// ------Appworkdemo live-------

// ------Farid Enpoints live-------
// export const API_ENDPOINT = "http://localhost:3030/api/";
// export const imagePath = "http://localhost:3030/uploads/";
// ------Farid Enpoints live-------

// ------Local -------
// export const API_ENDPOINT = "http://192.168.29.82:3030/api/";
// export const imagePath = "http://192.168.29.82:3030/uploads/";
// const API_ENDPOINT = "https://urban-doodle-vxggj44jx5vcxgj4-3030.app.github.dev/api/";
// export const imagePath = "https://urban-doodle-vxggj44jx5vcxgj4-3030.app.github.dev/uploads/";
// ------Local -------

const dataService = PagesIndex.axios.create({
  baseURL: API_ENDPOINT,
});

dataService.interceptors.request.use(
  (config) => {
    const token = JSON.parse(localStorage.getItem("token"));
    config.headers.Authorization = `Bearer ${token}`;
    return config;
    // Authorization: `Bearer ${data.token}`,
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default dataService;
